<template>
    <main-container>
        <header-container>
            <hospital-header-comp/>
        </header-container>
        <div style="height: 94vh; max-height: 94vh; padding-bottom: 20px; overflow-y: scroll; " id="scrollableDiv" >
            <select-region-and-seach-comp @switchTab="switchTab"></select-region-and-seach-comp>

            <!-- <div id="searchMe" style="display: block; position: relative;  top: 0; width: 100%; max-width: 425px; z-index: 1000;">
                <select-region-and-seach-comp @switchTab="switchTab"></select-region-and-seach-comp>
            </div>
            <div id="AllDetails" class="allDetail" style="display: none; ; z-index: 10;">
                <div>All Details</div>
                <img src="https://s3iconimages.mymedicine.com.mm/DropdownIcon.svg" @click="showSelectRegion"/>
            </div> -->
            <body-container v-if="selectedTab == 'hospital'">
                <div v-if="showHospitalLoader" style="display: flex; flex: 1; height:60vh; align-items:center; justify-content: center;">
                    <!-- <hospital-skeleton-loader />
                    <hospital-skeleton-loader />
                    <hospital-skeleton-loader /> -->
                    <img src="https://s3iconimages.mymedicine.com.mm/hospitalSearchGIF.gif" alt="" style="width: 250px; height: auto;">
                </div>
                <div v-else style="display: flex; flex-direction: column; gap: 16px; padding-top: 16px; position: relative; top: 0px;">
                    <div v-if="getHospitalList.length == 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px; color: grey;">
                        <img src="https://s3iconimages.mymedicine.com.mm/noContentFound.svg" alt="">
                        No Hospitals found
                    </div>
                    <div >
                        <single-hospital-comp v-for="(item) in getHospitalList" :key="item.hospitalRegNumber" :hospitalData="item" style="margin-bottom: 10px;"/>
                    </div>
                </div>
            </body-container>
            <div style="width: 100%;" v-else>
                <div v-if="showHospitalLoader" style="display: flex; flex: 1; height:60vh; align-items:center; justify-content: center;">
                    <img src="https://s3iconimages.mymedicine.com.mm/doctorSearchGif.gif" alt="" style="width: 250px; height: auto;">
                </div>
                <div v-else>
                    <doctor-flow-comp />
                </div>
            </div>
        </div>
    </main-container>
</template>
<script>
import axios from "axios";
import BodyContainer from '../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import mainContainer from '../../../templates/mainContainer.vue';
import HospitalSkeletonLoader from '../componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';
import HospitalHeaderComp from '../componentUsedInPage/opdHomePage/hospitalHeaderComp.vue';
import SelectRegionAndSeachComp from '../componentUsedInPage/opdHomePage/selectRegionAndSeachComp.vue';
import SingleHospitalComp from '../componentUsedInPage/opdHomePage/singleHospitalComp.vue';
import DoctorFlowComp from '../componentUsedInPage/opdHomePage/doctorFlowComp.vue';
export default {
  components: { mainContainer, BodyContainer, HeaderContainer, HospitalHeaderComp, SelectRegionAndSeachComp, SingleHospitalComp, HospitalSkeletonLoader, DoctorFlowComp },
    name: 'HospitalHomePage',
    data: function() {
        return {
            token: null,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            searchBoxDiv: null,
            allDetailsDiv: null,
            selectedTab: 'hospital',
        };
    },
    computed: {
        showHospitalLoader () {
            return this.$store.state.opdHospitalState.fetchingData;        
        },
        getHospitalList() {
            return this.$store.state.opdHospitalState.showHospitalList || [];        
        }
    },

    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        this.disableBackNavigation();
        // this.scrollableDiv = document.getElementById('scrollableDiv');
        // this.searchMeDiv = document.getElementById('searchMe');
        // this.searchBoxDiv = document.getElementById('searchBox');
        // this.allDetailsDiv = document.getElementById('AllDetails');
        // this.scrollableDiv.addEventListener('scroll', this.scrollHandler);
        this.$store.dispatch('resetOPDHospitalState');
        this.$store.dispatch('loadHospitalList');
        this.$store.dispatch('loadLocationList');
        this.$store.dispatch('OPDConfigCommonState/loadOPDSpecialty');  
        this.$store.dispatch('OPDConfigCommonState/loadAcademicsTitle');        
        
        this.userPageViewTable();
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'HospitalOPDHomePage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'HospitalOPDHomePage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        disableBackNavigation() {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = () => {
                this.$router.go(-1); // Prevent back navigation
            };
        },
        switchTab(value) {
            this.selectedTab = value;
        },
            // scrollHandler() {
            //     this.searchMeDiv.style.position = 'relative';
            //     this.searchMeDiv.style.top = 0;
            //     let rect = this.searchBoxDiv.getBoundingClientRect();
            //     let divTop = rect.top + window.scrollY;
            //     console.log(divTop)
            //     if(divTop < 10) {
            //     this.searchMeDiv.style.display = 'none';
            //     this.allDetailsDiv.style.display = 'flex'
            //     }

            // },
        showSelectRegion() {
            // this.scrollableDiv.removeEventListener('scroll', this.scrollHandler);
            // this.searchMeDiv.style.display = 'block';
            // this.searchMeDiv.style.position = 'sticky';
            // this.allDetailsDiv.style.display = 'none'
            setTimeout(() => {
                this.scrollableDiv.addEventListener('scroll', this.scrollHandler);
            }, 1000)
        },
    },
};
</script>
<style  scoped>
.allDetail {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background: white;
    border-bottom: 1px solid #ebebeb;
    position: sticky;
    max-width: 425px;
    top: 0;
}
</style>